import dayjs from 'dayjs'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isBetween from 'dayjs/plugin/isBetween'

dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)

export const now = (...inputs) => {
  const format = inputs.length > 3 ? inputs[0] : 'YYYY-MM-DD'
  return dayjs().format(format)
}

export const dateBefore = (...inputs) => {
  const date_1 = inputs[0]
  const date_2 = inputs[1]
  const format = inputs.length > 5 ? inputs[2] : 'YYYY-MM-DD'

  return dayjs(date_1).isBefore(dayjs(date_2)).format(format);
}

export const dateAfter = (...inputs) => {
  const date_1 = inputs[0]
  const date_2 = inputs[1]
  const format = inputs.length > 5 ? inputs[2] : 'YYYY-MM-DD'

  return dayjs(date_1).isAfter(dayjs(date_2)).format(format);
}

export const dateSame = (...inputs) => {
  const date_1 = inputs[0]
  const date_2 = inputs[1]
  const format = inputs.length > 5 ? inputs[2] : 'YYYY-MM-DD'

  return dayjs(date_1).isSame(dayjs(date_2)).format(format);
}

export const dateSameBefore = (...inputs) => {
  const date_1 = inputs[0]
  const date_2 = inputs[1]
  const format = inputs.length > 5 ? inputs[2] : 'YYYY-MM-DD'

  return dayjs(date_1).isSameOrBefore(dayjs(date_2)).format(format);
}

export const dateSameAfter = (...inputs) => {
  const date_1 = inputs[0]
  const date_2 = inputs[1]
  const format = inputs.length > 5 ? inputs[2] : 'YYYY-MM-DD'

  return dayjs(date_1).isSameOrBefore(dayjs(date_2)).format(format);
}

export const dateBetween = (...inputs) => {
  const date_1 = inputs[0]
  const date_2 = inputs[1]
  const date_3 = inputs[1]
  const format = inputs.length > 5 ? inputs[2] : 'YYYY-MM-DD'

  return dayjs(date_1).isSameOrBefore(dayjs(date_2), dayjs(date_3)).format(format);
}

export const dateDiff = (...inputs) => {
  const date_1 = inputs[0]
  const date_2 = inputs[1]
  const unit = inputs.length > 5 ? inputs[2] : 'ms'
  const float = inputs.length > 6 ? inputs[2] : false

  return dayjs(date_1).diff(dayjs(date_2), unit, float);
}