export const duplicateElement = (prefix, amount, element, overrides, state) => {

  const duplicates = {};
  for (let i = 0; i < amount; i++) {
    if ( overrides[i] ){
      duplicates[`${prefix}-${i}`] = {
        ...element,
        ...overrides[i]
      }
    } else {
      duplicates[`${prefix}-${i}`] = {
        ...element
      }
    }
  }

  return duplicates;
}