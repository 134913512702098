import axios from "axios";
import qs from "qs";

export const get = (...inputs) => {
  const url = inputs[0]
  const params = inputs.length >= 5 ? inputs[1] : {}
  const then_process = inputs.length >= 6 ? inputs[2] : null
  const catch_process = inputs.length >= 7 ? inputs[3] : null
  const finally_process = inputs.length >= 8 ? inputs[4] : null
  const options = inputs.length >= 9 ? inputs[5] : null
  // console.log("apiGet: ", url, params, then_process)
  
  let default_options
  if (options) {
    default_options = {
      headers: {
        'Content-type': 'application/json', 
        'Accept': 'application/json'
      },
      ...options
    };
  } else {
    default_options = {
      headers: {
        'Content-type': 'application/json', 
        'Accept': 'application/json'
      },
    };
  }


  return axios
    .get(url, {...default_options, params:params})
    .then((response) => {
      const process_result = then_process ? then_process({data: response.data}) : null;
      return { response: response, process_result: process_result };
    })
    .catch((error) => {
      const process_result = catch_process ? catch_process(error) : null;
      // console.log(error.response);
      return { error: error, process_result: process_result };
    })
    .finally(() => {
      const process_result = finally_process ? finally_process() : null;
      return { process_result: process_result };
    });
};

export const post = (...inputs) => {
  const url = inputs[0]
  const params = inputs.length >= 5 ? inputs[1] : {}
  const then_process = inputs.length >= 6 ? inputs[2] : null
  const catch_process = inputs.length >= 7 ? inputs[3] : null
  const finally_process = inputs.length >= 8 ? inputs[4] : null
  const options = inputs.length >= 9 ? inputs[5] : null
  // console.log("apiPOST", params)
  // const formData = new FormData();

  // Object.entries(params).forEach(([key, value]) => {
  //   formData.append(key, JSON.stringify(value));
  // })
  let default_options
  if (options) {
    default_options = {
      headers: {
        'Content-type': 'application/json', 
        'Accept': 'application/json'
      },
      ...options
    };
  } else {
    default_options = {
      headers: {
        'Content-type': 'application/json', 
        'Accept': 'application/json'
      },
    };
  }

  
  return axios
    .post(url, JSON.stringify(params), default_options)
    .then((response) => {
      // console.log("apiPost: ", response.data)
      const process_result = then_process ? then_process({data: response.data}) : null;
      return { response: response, process_result: process_result };
    })
    .catch((error) => {
      const process_result = catch_process ? catch_process(error) : null;
      return { error: error, process_result: process_result };
    })
    .finally(() => {
      const process_result = finally_process ? finally_process() : null;
      return { process_result: process_result };
    });
};

export const goto = (url, states) => {
  window.location.href = url;
};
